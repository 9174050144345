import React from "react"
import ReactDynamicImport from "react-dynamic-import"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"
import SEOImageTwitter from "@backgrounds/seo-image2.png"

const loader = () => {
  if (!detectMob()) {
    return import("@components/Landing/NewLanding")
  } else {
    return import("@components/Landing/LandingMobile")
  }
}

const Landing = ReactDynamicImport({ loader })

const IndexPage = ({ location }) => {
  return (
    <>
      <SEO
        title="Home"
        description="The World Leading Social Media Pods! Let's connect with thousands of people worldwide and level up your social media with the one-stop platform for knowledge, networking, business, growth, awareness, and research."
        imageTwitter={SEOImageTwitter}
      />
      <Landing location={location} />
    </>
  )
}

export default IndexPage
